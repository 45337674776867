
export default {
  data(){
    return {
      status:'1',
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      tabledata:[{
        OrderNo:'2021102312345',
        AddDTime:'1634978577',
        Price:'100.00',
        MPrice:'233.00',
        Count:1,
        LogFee:'18.00',
        Status:'1',
        PdtName:'测试商品,仅供测试',
        PdtAttr:'颜色：黑色 尺码：L',
        TotalMoney:'95.00',
      }]
    }
  },
  methods:{
    Detail(id){
      console.log(id);
      this.$router.push({path:'/orders/orderdetail'});
    }
  }
}
